import type { CookieAnalysis } from '@wix/thunderbolt-symbols'
import type { TimingEntry } from '@wix/thunderbolt-environment'

//	eslint-disable-next-line
;(function () {
	const { viewerModel, thunderboltVersion } = window
	const { site, rollout, fleetConfig, requestUrl, experiments } = viewerModel
	const frogOnUserDomain = experiments['specs.thunderbolt.frog_on_user_domain']
	const fedOpsAppName = site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt'
	const { isDACRollout, siteAssetsVersionsRollout } = rollout
	const is_dac_rollout = isDACRollout ? 1 : 0
	const is_sav_rollout = siteAssetsVersionsRollout ? 1 : 0
	const is_rollout = fleetConfig.code === 0 || fleetConfig.code === 1 ? fleetConfig.code : null
	const ts = Date.now() - window.initialTimestamps.initialTimestamp
	const tsn = Math.round(performance.now()) // client only
	const { visibilityState } = document
	const pageVisibilty = visibilityState
	const platformOnSite = viewerModel.siteFeatures.includes('platform')

	const extractServerTiming = (getTimingEntries: () => Array<TimingEntry>) => {
		let serverTiming: Array<any>
		try {
			serverTiming = getTimingEntries()
		} catch (e) {
			serverTiming = []
		}
		let microPop
		const matches: Array<string> = []
		serverTiming.forEach((st) => {
			switch (st.name) {
				case 'cache':
					matches[1] = st.description
					break
				case 'varnish':
					matches[2] = st.description
					break
				case 'dc':
					microPop = st.description
					break
				default:
					break
			}
		})
		return {
			microPop,
			matches,
		}
	}

	const extractCachingData = (cookie: string, getTimingEntries: () => Array<TimingEntry>): CookieAnalysis => {
		let microPop,
			caching = 'none'
		let match = cookie.match(
			/ssr-caching="?cache[,#]\s*desc=([\w-]+)(?:[,#]\s*varnish=(\w+))?(?:[,#]\s*dc[,#]\s*desc=([\w-]+))?(?:"|;|$)/
		)
		if (!match && window.PerformanceServerTiming) {
			const results = extractServerTiming(getTimingEntries)
			microPop = results.microPop
			match = results.matches
		}
		if (match && match.length) {
			caching = `${match[1]},${match[2] || 'none'}`
			if (!microPop) {
				microPop = match[3]
			}
		}
		if (caching === 'none') {
			const timing = typeof performance !== 'undefined' ? performance.timing : null
			if (timing && timing.responseStart - timing.requestStart === 0) {
				caching = 'browser'
			}
		}
		return {
			caching,
			isCached: caching.indexOf('hit') === 0,
			...(microPop ? { microPop } : {}),
		}
	}

	if (requestUrl.includes('suppressbi=true')) {
		return
	}
	const sendBeacon = (url: string): void => {
		const isIOS = (): boolean => /\(iP(hone|ad|od);/i.test(window?.navigator?.userAgent)
		let sent = false

		if (!isIOS()) {
			try {
				sent = navigator.sendBeacon(url)
			} catch (e) {
				//
			}
		}
		if (!sent) {
			new Image().src = url
		}
	}
	const { isCached, microPop } = extractCachingData(
		document.cookie,
		() => performance.getEntriesByType('navigation')[0].serverTiming || []
	)
	const ish = site.isSEO
	const frog = frogOnUserDomain ? site.externalBaseUrl.replace(/^https?:\/\//, '') + '/_frog' : '//frog.wix.com'
	const url =
		frog +
		'/bolt-performance?src=72&evid=' +
		'21' +
		'&appName=' +
		fedOpsAppName +
		'&is_rollout=' +
		is_rollout +
		'&is_sav_rollout=' +
		is_sav_rollout +
		'&is_dac_rollout=' +
		is_dac_rollout +
		'&dc=' +
		site.dc +
		(microPop ? '&microPop=' + microPop : '') +
		'&is_cached=' +
		isCached +
		'&msid=' +
		site.metaSiteId +
		'&session_id=' +
		window.fedops.sessionId +
		'&ish=' +
		ish +
		'&isb=' +
		ish +
		'&vsi=' +
		window.fedops.vsi +
		'&pv=' +
		pageVisibilty +
		'&pn=1' +
		'&v=' +
		thunderboltVersion +
		'&url=' +
		encodeURIComponent(requestUrl) +
		`&ts=${ts}&tsn=${tsn}` +
		'&platformOnSite=' +
		platformOnSite

	sendBeacon(url)
})()
